import React from 'react';
import { Link } from 'gatsby';

import Layout from 'components/Layout';
import LandScanSplash from '../assets/images/landscan_imagery_BG.png';

const LicensingPage = () => (
  <Layout>
    <div className="licensing-page subpage">
      <div
        className="licensing-page-header subpage__header"
        style={{
          backgroundImage: `url(${LandScanSplash})`
        }}
      >
        <h1>Licensing</h1>
      </div>
      <div className="licensing-page-content subpage__content">
        <p>
          The premier human geography foundation population datasets - LandScan
          Global, LandScan High-Definition (HD), and LandScan USA - are now
          being offered to the public without restrictions.
        </p>
        <p>
          These datasets are intended to aid in emergency preparedness,
          readiness, response, and recovery missions; risk assessments; site
          suitability studies; and a full range of other applications that
          depend on accurate population data.
        </p>
        <p>
          Begin your data exploration by selecting options from the product
          selector in the lower left-hand corner of the viewer - To download,
          select the download option and access the data.
        </p>
        <p>
          These datasets are offered under the Creative Commons Attribution 4.0
          International License. Users are free to use, copy, distribute,
          transmit, and adapt the data for commercial and non-commercial
          purposes, without restriction, as long as clear attribution of the
          source is provided. For more information, please refer to the{' '}
          <Link
            to="https://creativecommons.org/licenses/by/4.0/"
            target="_blank"
          >
            CC BY 4.0
          </Link>{' '}
          documentation.
        </p>
      </div>
    </div>
  </Layout>
);

export default LicensingPage;
